@import "assets/styles/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.cardTitle {
  padding: 0 16px;
}

.productList {
  display: flex;
  flex-direction: column;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid $border-gray;
  padding: 0 16px 16px;
}

.productInfo {
  display: flex;
  flex-direction: column;
}

.productTitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: $dark-blue;
}

.productCost {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $dark-blue;
  margin-bottom: 8px;
  text-align: right;
}

.productQuantity {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $text-secondary;
  text-align: right;
}

.invoice {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.invoiceAmount {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: $dark-blue;
}

.invoiceDiscount {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $text-secondary;
  margin-top: 8px;
}
