@import "assets/styles/vars";

.payment {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.items {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 16px;
}

.paymentLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
}

.paymentInfo {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;
}

.paymentDesc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $dark-blue;
}

.item {
  position: relative;
  display: flex;
  padding: 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-gray;
  }
}

.itemImage {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 12px;
  background-color: $bg-secondary;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.itemInfo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemLabel {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
}

.itemDesc {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $dark-blue;
  opacity: .5;
}

.itemPrice {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  white-space: nowrap;
}

.itemsCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.itemsCategoryTitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: $dark-blue;
  opacity: .5;
}

.itemsCategoryDelete {
  width: 24px;
  height: 24px;
  background-color: $bg-secondary;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentAgreement {
  margin-top: 8px;
}

.itemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.itemCounter {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 20px;
  background-color: $bg-secondary;
}

.itemCount {
  display: flex;
  justify-content: center;
  width: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
}

.itemCounterBtn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.card {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;
}

.paymentMethodsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.paymentMethod {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: $dark-blue;

  &.disabled {
    color: $text-secondary;
  }
}

.paymentMethodsImages {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 16px;

  & > div {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .sbp {
    width: 35px;
    height: 18px;
    background-image: url("img/pay-sbp.png");
  }

  .mc {
    width: 23px;
    height: 14px;
    background-image: url("img/pay-mc.png");
  }

  .visa {
    width: 31px;
    height: 10px;
    background-image: url("img/pay-visa.png");
  }

  .mir {
    width: 33px;
    height: 9px;
    background-image: url("img/pay-mir.png");
  }
}

.paymentMethodBtn {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 2px solid $dark-blue;
  margin-right: 16px;

  &.disabled {
    border: 2px solid $text-secondary;
  }
}

.paymentMethodBtnActive {
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 2px solid $dark-blue;
  margin-right: 16px;

  &::before {
    position: absolute;
    top: 2px;
    left: 2px;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: $dark-blue;
  }
}

.paymentInfoDescTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $dark-blue;
}

.paymentInfoDescText {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $dark-blue;
}
