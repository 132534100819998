@import "assets/styles/vars";
@import "assets/styles/animations";

:global {
  .__pinned {
    padding-top: 24px;
    backdrop-filter: blur(6px);
  }
}

.top {
  z-index: 2;
  position: sticky;
  top: -1px;
  padding: 8px 16px;
  transition: padding-top 0.15s;
  border-bottom: 1px solid #E6E6E6;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hasAction {
  padding: 8px 44px;
}

.title {
  display: -webkit-box;
  max-height: 48px;
  max-width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $dark-blue;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $dark-blue;
}

.smallText {
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: $dark-blue;
}

.close {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.back {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.loadingSmallText {
  display: flex;
  height: 24px;
  width: 60%;
  border-radius: 8px;

  @include empty-animation;
}

.loadingTitle {
  display: flex;
  height: 24px;
  width: 100%;
  border-radius: 8px;

  @include empty-animation;
}

.loadingText {
  display: flex;
  height: 24px;
  width: 60%;
  border-radius: 8px;

  @include empty-animation;
}

.loadingHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
