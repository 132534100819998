@import "assets/styles/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.items {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 16px;
}

.item {
  position: relative;
  display: flex;
  padding: 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-gray;
  }
}

.itemImage {
  flex: 0 0 auto;
  width: 80px;
  height: 80px;
  margin-right: 12px;
  background-color: $bg-secondary;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.itemShortImage {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background-color: $bg-secondary;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.itemInfo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemLabel {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
  min-height: 24px;
}

.itemDesc {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $dark-blue;
  opacity: .5;
}

.itemPrice {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  white-space: nowrap;
}

.itemAmount {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  color: $text-secondary;
  text-align: right;
}

.itemsCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.itemsCategoryTitle {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: $dark-blue;
  opacity: .5;
}

.itemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.itemShortInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.itemShortBlock {
  display: flex;
  flex-direction: column;
}

.amountCard {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
}

.amountCardLoading {
  opacity: .6;
}

.amountItem {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
}

.amountSum {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: $dark-blue;
}

.cartEmpty {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $dark-blue;
  text-align: center;
}

.itemDelete {
  display: flex;
  flex: 0 0 24px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: $gray;
  border-radius: 4px;
}
