@import "assets/styles/vars";

.container {
  height: 100%;
  min-height: calc(100dvh - 160px);
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -5px 50px rgba(0,0,0,.75);
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  position: relative;
  z-index: 4;
  background-color: $gray;
  transition: all .5s cubic-bezier(0.5, 0.01, 0, 0.99);
}

.containerBottom {
  padding-bottom: 84px;
}

.header {
  display: flex;
}

.content {
  padding: 16px 8px;
}

.containerTop {
  margin-top: -120px;
}

