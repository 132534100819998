@import "src/assets/styles/vars";

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px 8px;
  z-index: 5;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #F3F3F3 40%);
}

.bottomInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background-color: $white;
  padding: 6px;
  box-shadow: 0 4px 50px 0 #0000001A;

  svg {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;

    & > circle {
      transform-origin: center;
    }
  }
}

.bottomInnerLoading {
  .bottomButtonOrder {
    background-color: $green-light;
  }

  .bottomButtonInfo {
    opacity: .6;
  }
}

.bottomButtonOrder {
  height: 40px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: $green;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
  text-align: center;
}

.bottomButtonInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 8px;
}

.bottomButtonTotal {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: $dark-blue;

  & > span:first-of-type {
    margin-right: 4px;
  }
}

.bottomButtonAmount {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-blue;
  margin-left: 16px;

  & > span:first-of-type {
    margin-right: 4px;
  }
}

.paymentInner {
  display: flex;
  border-radius: 50px;
  background-color: $green;
  padding: 16px;
  box-shadow: 0 4px 50px 0 #0000001A;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;

  & > span {
    width: 100%;
    text-align: center;
  }
}

.innerText {
  border-radius: 50px;
  background-color: $green;
  padding: 16px;
  box-shadow: 0 4px 50px 0 #0000001A;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
  text-align: center;
  user-select: none;
}

.innerTextLoading {
  background-color: $green-light;
}

.productBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background-color: $white;
  padding: 6px;
  box-shadow: 0 4px 50px 0 #0000001A;
}

.productBottomCounter {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productBottomButton {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: $bg-secondary;

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.productBottomButtonDisabled > svg {
  opacity: 0.25;
}

.productBottomAmount {
  width: 42px;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: $dark-blue;
}

.productBottomAction {
  height: 40px;
  min-width: 180px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: $green;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
  text-align: center;
}

.productBottomActionDelete {
  background-color: $dark-blue;
}

.productBottomActionDisabled {
  background-color: $green-light;
}

.disabled {
  background-color: $green-light;
}
