@import "assets/styles/vars";

.counter {
  display: block;
}

.counterCart {
  .counterInner {
    height: 32px;
    border-radius: 20px;
    background-color: $bg-secondary;
  }

  .counterAmount {
    min-width: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: $dark-blue;
  }

  .counterButton {
    height: 32px;
    width: 32px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
}

.counterProductCard {
  .counterInner {
    height: 40px;
    border-radius: 12px;
    background-color: $bg-secondary;
  }

  .counterAmount {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: $dark-blue;
  }

  .counterButton {
    height: 40px;
    width: 40px;

    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

.counterInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counterButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counterButtonDisabled > svg {
  opacity: 25%;
}

