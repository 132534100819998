@import "assets/styles/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;
  color: $dark-blue;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.failInfo {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $dark-blue;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 14px;
  color: $dark-blue;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 16px 8px;
  z-index: 5;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #F3F3F3 40%);
}

.innerText {
  border-radius: 50px;
  background-color: $green;
  padding: 16px;
  box-shadow: 0 4px 50px 0 #0000001A;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $white;
  text-align: center;
  user-select: none;
}
