@import "assets/styles/vars";

.statusInfoStr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: $dark-blue;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.statusInfoLabel {
  font-weight: 400;
}

.statusInfoValue {
  font-weight: 500;
}
